var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view view--elitepage view--mt-0" },
    [
      _vm.mailingListPage
        ? [
            _c(
              "header",
              {
                staticClass: "header header--sm",
                style: { backgroundColor: _vm.mailingListPage.header_bg_color },
              },
              [
                _vm.mailingListPage.header_image_url
                  ? _c("img", {
                      staticClass: "section__bgOverlay",
                      style: {
                        opacity: _vm.mailingListPage.header_dim_amount / 100,
                      },
                      attrs: { src: _vm.mailingListPage.header_image_url },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "container h-textCenter" }, [
                  _c("h1", { staticClass: "h1 h1--eliteTitles" }, [
                    _vm._v(_vm._s(_vm.mailingListPage.header_text)),
                  ]),
                ]),
              ]
            ),
            _c("section", { staticClass: "section section--sm" }, [
              _c(
                "div",
                { staticClass: "container container--sm h-textCenter" },
                [
                  _c("h1", { staticClass: "h1" }, [
                    _vm._v(_vm._s(_vm.mailingListPage.text_title)),
                  ]),
                  _c("p", { staticClass: "p textSizeMd" }, [
                    _vm._v(_vm._s(_vm.mailingListPage.text_content1)),
                  ]),
                  _vm.mailingListPage.youtube_video_id
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flexbox flexbox--center flexWrap h-margin-60",
                        },
                        [
                          _c("iframe", {
                            attrs: {
                              src:
                                "https://www.youtube.com/embed/" +
                                _vm.mailingListPage.youtube_video_id,
                              width: "560",
                              height: "315",
                              frameborder: "0",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c("p", { staticClass: "p textSizeMd" }, [
                    _vm._v(_vm._s(_vm.mailingListPage.text_content2)),
                  ]),
                  _c("PMailingListSignup", {
                    staticClass: "h-mt-40",
                    attrs: { isForMailingListPage: true },
                  }),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }